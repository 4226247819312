'use strict';
import Vue from 'vue'

const LanguagesService = {

    getLanguagesByCountry(country) {
        console.log("getLanguagesByCountry %O %s", country, typeof country);
        console.log(country.id_country);
        return new Promise((resolve, reject) => {
            let url = '/master/languages/' + country.id_country;
            console.log("url: %O", url);
            Vue.prototype.$http.get(url)
                .then(response => {
                    return resolve(response)
                })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        });
    },
    getTextLanguages(data) {
        console.log("getTextLanguages");

        return new Promise((resolve, reject) => {
            let language = data.id_language == null ? "" : data.id_language;
            let url = `/master/language/${data.id_country}/${language}/`;
            Vue.prototype.$http.get(url)
                .then(response => {

                    return resolve(response)
                })
                .catch(error => { reject({ ret: error.response.status, msg: error.response.data }); });
        });
    },
    getDefaultTextLanguages() {
        console.log("getDefaultTextLanguages");

        return new Promise((resolve, reject) => {
            console.log("dentro primise getDefaultTextLanguages");
            let url = "/master/language/default/";
            Vue.prototype.$http.get(url).then(response => {
                console.log("respuesta: %O",response);
                    return resolve(response)
                })
                .catch(error => {console.error("dentro error %O",error); reject({ ret: error.response.status, msg: error.response.data }); });
        });
    },
    getTranslations() {
        return new Promise((resolve, reject) => {
            console.log("dentro primise getDefaultTextLanguages");
            let url = "/api/i18n/console/all";
            Vue.prototype.$http.get(url).then(response => {
                console.log("respuesta: %O",response);
                    return resolve(response)
                })
                .catch(error => {console.error("dentro error %O",error); reject({ ret: error.response.status, msg: error.response.data }); });
        });
    }

}

export default LanguagesService;