'use strict';
import Vue from 'vue'

const AuthService = {
  login(username, password) {

    return new Promise((resolve, reject) => {
      
      Vue.prototype.$http.post('console/login', { username: username, password: password})
        .then(response => {
          console.log("resolve login: %O", response);
          resolve(response);
        })
        .catch(error => { 
          console.log("error catcher login %O",error.response.data);
          reject(error.response.data); })
    })

  },
  ciamAuthentication(token) {
    return new Promise((resolve, reject) => {
      Vue.prototype.$http.post('console/authenticateCiamUser', { token })
        .then(response => {
          console.log("resolve login: %O", response);
          resolve(response);
        })
        .catch(error => { 
          console.log("error catcher login %O",error.response.data);
          reject(error.response.data); })
    })
  },
 /*login(username, password, plant) {

    return new Promise((resolve, reject) => {
     
        resolve(
          { data: 
          {
            "ret":1,
            "locale":"pl",
            "id_country":3,
            "country":"PL",
            "theme":"laFargeGreenAlternative",
            "id_user":1,
            "username":"admin",
            "fullname":"prueba name prueba last",
            "email":"p@a.com",  
            "id":1,
            "token":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWRfdXNlciI6MSwidXNlcm5hbWUiOiJhZG1pbiIsImlhdCI6MTYwNjgxODIyOCwiZXhwIjoxNjA2OTA0NjI4fQ.I8Ba1dZds6qNXIweQfxu_zHx7bJe6pifZuEWhBhKrps"
         }}


        );*/
     /* Vue.prototype.$http.post('/security/login', { username: username, password: password, plant: plant })
        .then(response => {
          console.log("resolve login: %O", response);
          resolve(response);
        })
        .catch(error => { 
          console.log("error catcher login %O",error.response.data);
          reject(error.response.data); })*/

  /* })

  },*/

 
}



export default AuthService;

